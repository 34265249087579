import { Heading, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { IoCaretBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import colors from '../Utils/colors';

function TermConditionPage() {
  const navigate = useNavigate();

  return (
    <Stack m={5}>
      <HStack
        cursor="pointer"
        w={'100px'}
        mb={3}
        p={2}
        alignItems="center"
        shadow={'base'}
        justifyContent={'center'}
        borderRadius="full"
        bgColor={colors.theme}
        onClick={() => navigate(-1)}
      >
        <IoCaretBackOutline size={15} />
        <Text fontSize={'xs'} letterSpacing={0.5}>
          Back
        </Text>
      </HStack>

      <Heading fontSize={'md'}>Syarat dan Ketentuan Mitra Importir.com</Heading>
      <Text>
        Importir.com menyediakan layanan berbasis digital untuk mendukung
        aktivitas penggunanya. Dengan menggunakan layanan ini, Anda setuju untuk
        tunduk pada Syarat dan Ketentuan yang telah ditetapkan di bawah ini.
        Mohon baca dengan saksama sebelum menggunakan layanan kami.
      </Text>

      <Heading fontSize={'md'} py={1}>1. Definisi</Heading>
      <Stack spacing={2}>
        <Text>1.1 Importir.com adalah platform digital yang membantu dalam melakukan proses importasi barang ke Indonesia.</Text>
        <Text>1.2 Pengguna adalah setiap individu atau entitas yang mengakses dan/atau menggunakan layanan di Importir.com.</Text>
        <Text>1.3 Layanan mencakup semua fitur dan fasilitas yang tersedia di website Importir.com.</Text>
      </Stack>

      <Heading fontSize={'md'} py={1}>2. Ketentuan Penggunaan</Heading>
      <Stack spacing={2}>
        <Text>2.1 Pengguna diwajibkan untuk menyediakan informasi yang akurat dan terbaru saat menggunakan layanan kami, termasuk dalam proses pendaftaran.</Text>
        <Text>2.2 Pengguna bertanggung jawab penuh atas kerahasiaan akun dan kata sandi mereka. Aktivitas yang dilakukan melalui akun pengguna sepenuhnya menjadi tanggung jawab pengguna tersebut.</Text>
        <Text>2.3 Pengguna dilarang menggunakan layanan untuk aktivitas yang melanggar hukum, merugikan pihak lain, atau bertentangan dengan ketentuan yang berlaku.</Text>
      </Stack>

      <Heading fontSize={'md'} py={1}>3. Privasi dan Perlindungan Data</Heading>
      <Stack spacing={2}>
        <Text>3.1 Kami berkomitmen untuk melindungi data pribadi Anda sesuai dengan peraturan perundang-undangan yang berlaku di Indonesia, termasuk UU No. 27 Tahun 2022 tentang Perlindungan Data Pribadi.</Text>
        <Text>3.2 Data pribadi yang dikumpulkan akan digunakan untuk keperluan layanan kami, seperti verifikasi, analisis, dan pengembangan layanan.</Text>
        <Text>3.3 Pengguna memiliki hak untuk mengakses, memperbaiki, dan/atau menghapus data pribadi mereka dengan menghubungi tim kami di info@importir.org.</Text>
      </Stack>

      <Heading fontSize={'md'} py={1}>4. Pembatasan Tanggung Jawab</Heading>
      <Stack spacing={2}>
        <Text>4.1 Importir.com tidak bertanggung jawab atas kerugian yang disebabkan oleh kesalahan pengguna dalam mengakses layanan atau memberikan informasi yang tidak akurat.</Text>
        <Text>4.2 Kami berhak untuk menghentikan layanan kepada pengguna yang terbukti melanggar Syarat dan Ketentuan ini tanpa pemberitahuan sebelumnya.</Text>
        <Text>4.3 Layanan yang tersedia disediakan sebagaimana adanya (as is) tanpa jaminan apa pun, baik tersurat maupun tersirat.</Text>
      </Stack>

      <Heading fontSize={'md'} py={1}>5. Hak Kekayaan Intelektual</Heading>
      <Stack spacing={2}>
        <Text>5.1 Semua hak kekayaan intelektual atas konten di Importir.com, termasuk teks, gambar, desain, dan merek dagang, adalah milik Importir.com.</Text>
        <Text>5.2 Pengguna tidak diizinkan untuk menyalin, mendistribusikan, atau menggunakan konten kami tanpa izin tertulis.</Text>
      </Stack>

      <Heading fontSize={'md'} py={1}>6. Perubahan Syarat dan Ketentuan</Heading>
      <Stack spacing={2}>
        <Text>6.1 Importir.com berhak untuk mengubah atau memperbarui Syarat dan Ketentuan ini kapan saja dengan pemberitahuan melalui platform kami.</Text>
        <Text>6.2 Pengguna yang terus menggunakan layanan setelah perubahan dianggap telah menyetujui Syarat dan Ketentuan yang baru.</Text>
      </Stack>

      <Heading fontSize={'md'} py={1}>7. Hukum yang Berlaku dan Penyelesaian Sengketa</Heading>
      <Stack spacing={2}>
        <Text>7.1 Syarat dan Ketentuan ini tunduk pada hukum Republik Indonesia.</Text>
        <Text>7.2 Segala sengketa yang timbul akan diselesaikan melalui mediasi terlebih dahulu. Jika mediasi tidak mencapai kesepakatan, sengketa akan diselesaikan di pengadilan yang berwenang.</Text>
      </Stack>

      <Heading fontSize={'md'} py={1}>9. Kontak Kami</Heading>
      <Stack spacing={2}>
        <Text>Untuk informasi lebih lanjut, silakan hubungi:</Text>
        <Text>Email: info@importir.org</Text>
        <Text>Alamat: Jl. Puri Utama No.7 Blok C2, RT.004/RW.008, Petir, Kec. Cipondoh, Kota Tangerang, Banten 15147.</Text>
      </Stack>
    </Stack>
  );
}

export default TermConditionPage;
